import axios from 'axios';
import { getItem } from "common/utils/Storage/Storage";
import { baseUrl } from 'common/utils/CommonFunctions';

const token = getItem('login-token');

const instance = axios.create({
    baseURL: baseUrl+'/api',
    headers: { Authorization: `Bearer ${token}` },
});

export default instance;
